import React, {useState, useEffect} from 'react';
import { client } from '../client'
import ProjectCard from './ProjectCard';

export default function Project() {
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    client.fetch(`*[_type == "project"]{
      title,
      slug,
      date,
      company,
      place,
      description,
      projectType,
      link,
      tags
    }`).then((data) => setProjectData(data)).catch(console.error)
  }, [])
 
  return (
    <main className='min-h-screen p-12'>
      <section className='container mx-auto'>
        <h1 className='text-3xl lg:text-5xl flex text-white justify-center cursive pb-12'>My Projects</h1>
        
        <h2 className='text-xl lg:text-3xl flex text-white cursive'>Personal Projects</h2>
        <h2 className='text-base lg:text-xl text-gray-400 flex mb-6 lg:mb-12'>Projects I made on my own time. </h2>
        <section className='grid grid-cols-1 lg:grid-cols-2 gap-8 mb-24'>
        {projectData && projectData.filter(project => project.projectType === 'personal').map((project, index) => (
          <ProjectCard project={project} key={project.title}/>
        ))}
        </section>

        <h2 className='text-xl lg:text-3xl flex text-white cursive'>Client Projects</h2>
        <h2 className='text-base lg:text-xl text-gray-400 flex mb-6 lg:mb-12'>Projects I worked on in the industry that I can talk about. </h2>
        <section className='grid grid-cols-1 lg:grid-cols-2 gap-8 mb-24'>
        {projectData && projectData.filter(project => project.projectType === 'client').map((project, index) => (
          <ProjectCard project={project} key={project.title}/>
        ))}
        </section>

        <h2 className='text-xl lg:text-3xl flex text-white cursive'>School Projects</h2>
        <h2 className='text-base lg:text-xl text-gray-400 flex mb-6 lg:mb-12'>Significant projects I worked on through my college career. </h2>
        <section className='grid grid-cols-1 lg:grid-cols-2 gap-8 mb-24'>
        {projectData && projectData.filter(project => project.projectType === 'school').map((project, index) => (
          <ProjectCard project={project} key={project.title}/>
        ))}
        </section>


      </section>
    </main>
  );
}
