import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {client} from '../client'
import { BsFillPinMapFill, BsCalendar4, BsCodeSlash } from "react-icons/bs";

export default function SinglePost() {
  const [singlePost, setSinglePost] = useState(null)
  const { slug } = useParams();
  useEffect(() => {
    client.fetch(`*[slug.current == "${slug}"]{
      title,
      _id,
      slug,
      experiences[]->{..., image{asset->{_id,url}}},
      mainImage{
        asset->{
          _id,
          url
        }
      },
      body,
      "name": author->name,
      "authorImage": author->image
    }`).then((data) => setSinglePost(data[0])).catch(console.error)
  }, [slug])
  
  if (!singlePost) return <div>Loading...</div>

  return (
    <main className='min-h-screen p-6 lg:p-12'>
      {singlePost.experiences.length > 0 && singlePost.experiences.map((experience, index) => (
        <article className='container shadow-lg mx-auto bg-gray-600 rounded-lg mb-24' key={`${index}-e`}>
          <header className='relative'>
            <img src={experience.image.asset.url} alt={singlePost.title} className='w-full object-cover rounded-t h-20 lg:h-80 2xl:h-96'/>
          </header>
          <div className='px-4 lg:px-48 py-6 lg:py-20 max-w-full'>
            <h3 className='flex cursive text-xl lg:text-4xl font-bold text-blue-100 pb-2'>{experience.position}</h3>
            <div className='flex flex-row content-center'>
              <BsCodeSlash className='lg:mt-1 mr-5' color='#ffff'/>
              <h2 className='flex text-xs lg:text-base font-medium text-white pb-2 text-center'>{experience.stack}</h2>
            </div>
            <div className='flex flex-row content-center'>
              <BsFillPinMapFill className='lg:mt-1 mr-5' color='#ffff'/>
              <h2 className='flex text-xs lg:text-base font-medium text-white pb-2 text-center'>{experience.location}</h2>
            </div>
            <div className='flex flex-row content-center pb-12'>
              <BsCalendar4 className='lg:mt-1 mr-5' color='#ffff'/>
              <h2 className='flex text-xs lg:text-base font-medium text-white text-center'>{`${experience.startDate} - ${experience.endDate}`}</h2>
            </div>
            <h2 className='flex text-sm leading-7 lg:text-lg lg:font-medium text-white pb-6'>{experience.overview}</h2>
            <h3 className='flex cursive text-lg lg:text-xl font-bold text-blue-100 pb-2'>{experience.endDate === 'Current' ? `What I'm Doing:` : `What I Did:`}</h3>
            {experience.tasks.length > 0 && experience.tasks.map((task, index) => (
              <h2 className='flex text-sm leading-7 lg:text-lg lg:font-medium text-white pb-2' key={index}>{`- ${task}`}</h2>
            ))}
          </div>
        </article>
      ))
      }
    </main>
  );
}
