import React, {useState, useEffect} from 'react';
import { client } from '../client'
import SkillsCard from './SkillsCard';

export default function Skills() {
  const [skillData, setSkillData] = useState(null);
  
  useEffect(() => {
    client.fetch(`*[_type == "skill"]{
      title,
      type,
      image{
        asset->{
          _id,
          url
        },
      }
    }`).then((data) => setSkillData(data))
    .catch(console.error)
  }, [])
  
  return (
    <main className='min-h-screen p-12'>
      <section className='container mx-auto'>
        <h1 className='text-3xl lg:text-5xl flex justify-center text-white cursive pb-4'>Skills</h1>
        <h2 className='text-base lg:text-xl text-gray-400 flex justify-center mb-12 lg:mb-24'>Here are the technologies I have played with in one form or another!</h2>
        
        <h2 className='text-xl lg:text-3xl flex text-white cursive'>Web Development</h2>
        <h2 className='text-sm lg:text-xl text-gray-400 flex mb-8 lg:mb-12'>Web related technologies I've used recently. </h2>
        <div className='grid grid-cols-2 gap-1 mb-24 lg:grid-cols-5 lg:gap-12 lg:mb-32'>
        {skillData && skillData.filter(skill => skill.type === 'web').map((skill) => (
          <SkillsCard skill={skill} key={skill.title}/>
        ))}
        </div>

        <h2 className='text-xl lg:text-3xl flex text-white cursive'>Other Programming Languages</h2>
        <h2 className='text-sm lg:text-xl text-gray-400 flex mb-8 lg:mb-12'>Some other languages I've played with, in or out of the classroom. </h2>
        <div className='grid grid-cols-2 gap-5 mb-24 lg:grid-cols-5 lg:gap-12 lg:mb-32'>
        {skillData && skillData.filter(skill => skill.type === 'languages').map((skill) => (
          <SkillsCard skill={skill} key={skill.title}/>
        ))}
        </div>

        <h2 className='text-xl lg:text-3xl flex text-white cursive'>Other Skills</h2>
        <h2 className='text-sm lg:text-xl text-gray-400 flex mb-8 lg:mb-12'>Some other skills I've picked up on, either in Industry or on my own. </h2>
        <div className='grid grid-cols-2 gap-5 mb-24 lg:grid-cols-5 lg:gap-12 lg:mb-32'>
        {skillData && skillData.filter(skill => skill.type === 'other').map((skill) => (
          <SkillsCard skill={skill} key={skill.title}/>
        ))}
        </div>
        

      </section>
    </main>
  );
}
