import React from 'react'
import { Link } from 'react-router-dom';

export default function RoutingCard( {section} ) {
  
    return (
    <div className='mx-3 mb-8 lg:mx-0 lg:mb-12'>
        <article className='mb-2 lg:mb-4'>
            <span className='rounded-lg block h-64 relative rounded shadow-2xl leading-snug bg-white' >
                <img 
                src={section.mainImage.asset.url}
                alt={section.mainImage.alt}
                className='w-full h-full rounded object-cover absolute'
                />
            </span>
        </article>

        <Link to={'/' + section.route} key={section.route}>
            <h3 className='cursive text-center text-gray-200 text-lg py-3 bg-slate-600 hover:bg-slate-300 hover:text-bold hover:text-green-600 bg-opacity-75 rounded'>{section.title}</h3>
        </Link>
    </div>
  )
}
