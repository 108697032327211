import React from 'react'
import { Link } from 'react-router-dom';

export default function ProjectCard({project}) {
  return (
    <div className='relative text-center rounded-large shadow-xl bg-gray-300 px-8 lg:px-12 py-8' key={project.title}>
        <h3 className='text-gray-800 text-xl lg:text-3xl font-bold'>
            {project.title}
        </h3>
        <div>
            {project.company &&
                <p className='font-bold text-base text-gray-500'>{project.company}</p>
            }
            <div className='mt-4 mb-4 lg:mb-8'>
                {project.tags.length > 0 && project.tags.map((tag) => (
                    <p key={`${project.title}-${tag}`} className='bg-emerald-400/75 lg:inline lg:px-4 lg:mr-2 py-2 rounded-full mb-2 lg:mb-6 text-center font-bold text-xs lg:text-sm text-gray-800'>{tag}</p>
                ))}
            </div>
            <p className='mt-6 mb-16 text-sm lg:text-lg text-gray-700 leading-relaxed'>{project.description}</p>
            <Link to={'/projects/' + project.slug.current} key={project.slug.current}>
                <h3 className='absolute bottom-5 left-6 lg:left-12 w-5/6 cursive text-center text-lg py-3 bg-sky-900/75 text-gray-200 hover:bg-sky-800/75 hover:text-bold hover:text-sky-200 hover:underline rounded'>Learn More</h3>
            </Link>
        </div>
    </div>
  )
}
