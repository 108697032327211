import React, {useState, useEffect} from 'react';
import {client} from '../client'
import portrait from '../assets/p4.jpg'
import huskers from '../assets/huskers.jpg'
import BlockContent from '@sanity/block-content-to-react'

export default function About({isMobile}) {
  const [author, setAuthor] = useState(null)

  useEffect(() => {
    client.fetch(`*[_type == "author"]{
      name,
      bio,
      education,
      "authorImage": image.asset->url
    }`).then((data) => setAuthor(data[0])).catch(console.error)
  }, [])

  if (!author) return <div>Loading...</div>
  
  return (
    <main className="relative">
      <div className='p-6 lg:p-10 lg:pt-32 container mx-auto relative'>
        <div className='bg-slate-700 rounded-lg shadow-2xl lg:flex lg:flex-col items-center px-4 lg:p-20'>
          {!isMobile && <div className='text-lg flex flex-row justify-center items-center border-b-2'>
            <img src={portrait} alt={author.name} className='rounded w-64 h-64 mb-6 mr-12'/>
            <h1 className='cursive text-6xl text-green-300 mb-4'>
              Hey there. I'm{' '}
              <span className='text-green-100'>{author.name}</span>
            </h1>
          </div>}
          {isMobile && <div className='flex flex-col justify-center items-center align-middle border-b-2'>
            <img src={portrait} alt={author.name} className='mt-6 rounded w-64 h-64 mb-6'/>
            <h1 className='cursive text-2xl text-green-300 mb-4'>
              Hey there. I'm{' '}
              <span className='text-green-100'>{author.name}</span>
            </h1>
          </div>}
          <div className='text-base flex flex-col justify-center'>
            <div className='prose lg:prose-xl text-white'>
              <BlockContent blocks={author.bio} projectId='g5c9f8kd' dataset='production'/>
            </div>
          </div>
        </div>
      </div>

      <div className='p-6 lg:p-10 container mx-auto relative'>
      
        <div className='bg-slate-700 rounded-lg shadow-2xl lg:flex lg:flex-col items-start px-4 lg:p-20'>
          
          {!isMobile && <div className='text-lg flex flex-row justify-center items-center border-b-2'>
            <img src={huskers} alt={author.name} className='rounded w-64 h-64 mb-6 mr-12'/>
            <div className='flex flex-col'>
              <h1 className='cursive text-5xl font-bold text-red-600 mb-2'>
                University of Nebraska-Lincoln
              </h1>
              <h1 className='text-white  text-2xl cursive'>
                - Bachelor of Science in{' '}
                <span className='text-green-100'>{'Software Engineering'}</span>
              </h1>
              <h1 className='text-white  pl-6 text-lg cursive mb-2'>
                Minor in{' '}
                <span className='text-green-100'>{'Mathematics'}</span>
              </h1>
              <h1 className='text-white  text-2xl cursive mb-2'>
                - Cumulative GPA of{' '}
                <span className='text-green-100'>{'3.7/4.0'}</span>
              </h1>
              <h1 className='text-white  text-2xl cursive mb-2'>
                - Graduated{' '}
                <span className='text-green-100'>{'May, 2020'}</span>
              </h1>
            </div>
          </div>}

          {isMobile && <div className='flex flex-col justify-center items-center align-middle border-b-2'>
            <img src={huskers} alt={author.name} className='mt-6 rounded w-64 h-64 mb-2'/>
            <div className='flex flex-col'>
              <h1 className='text-center cursive text-xl font-bold text-red-600 mb-2'>
                University of Nebraska-Lincoln
              </h1>
              <h1 className='text-white text-base cursive'>
                - BS in{' '}
                <span className='text-green-100'>{'Software Engineering'}</span>
              </h1>
              <h1 className='text-white text-sm pl-4 cursive mb-2'>
                Minor in{' '}
                <span className='text-green-100'>{'Mathematics'}</span>
              </h1>
              <h1 className='text-white text-base cursive mb-2'>
                - Cumulative GPA of{' '}
                <span className='text-green-100'>{'3.7/4.0'}</span>
              </h1>
              <h1 className='text-white text-base cursive mb-2'>
                - Graduated{' '}
                <span className='text-green-100'>{'May, 2020'}</span>
              </h1>
            </div>
          </div>}

          <div className='text-lg flex flex-col justify-center pb-3 lg:pb-0'>
            <h1 className='text-white cursive text-xl lg:text-3xl mt-5'>Additional Accomplishments</h1>
            <div className='prose lg:prose-xl text-white'>
              <BlockContent blocks={author.education} projectId='g5c9f8kd' dataset='production'/>
            </div>
          </div>
        </div>
      </div>

      
    </main>
  );
}
