import React from 'react'
import { Link } from 'react-router-dom';

export default function ExperienceCard( {post} ) {
  
    return (
    <article className='transition ease-in-out hover:-translate-y-1 hover:scale-110'>
        <Link to={'/experience/' + post.slug.current} key={post.slug.current}>
        <span className='rounded-lg block h-48 lg:h-64 relative rounded shadow-2xl leading-snug bg-white border-l-8 border-slate-500' >
            <img 
            src={post.mainImage.asset.url}
            alt={post.mainImage.alt}
            className='w-full h-full rounded object-cover absolute'
            />
        </span>
        </Link>
    </article>
  )
}
