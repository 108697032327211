import React, {useState, useEffect} from 'react';
import portrait from '../assets/p1.jpg'
import {client} from '../client'
import RoutingCard from './RoutingCard';

const homeText = 'I built this page using React.js, TailwindCSS, and Sanity.io to '
  + 'use as a hub for showcasing my skills and experiences. Check out the links below to get a more in-depth '
  + 'look at my development career.'

export default function Home({isMobile}) {
  const [cards, setCards] = useState(null);
  
  useEffect(() => {
    client.fetch(`*[_type == "section"]{
      title,
      route,
      priority,
      mainImage{
        asset->{
          _id,
          url
        },
        alt
      }
    }`).then((data) => setCards(data.sort((a,b) => a.priority > b.priority ? 1 : -1)))
    .catch(console.error)
  }, [])
  
  return (
    <div className='flex flex-col items-center'>
      <div className='flex justify-center pt-12 lg:pt-64 px-8'>
          <div>
            <h1 className='mb-3 lg:mb-5 text-green-100 font-bold cursive leading-none text-center lg:leading-snug text-3xl lg:text-8xl 2xl:text-9xl'>Hi there, I'm Jake.</h1>
            {!isMobile && <h2 className='text-gray-200 font-bold cursive justify-center text-center text-base lg:text-4xl'>Software Engineer. Full stack web developer.</h2>}
            {isMobile && 
              <div>
                <h2 className='text-gray-200 font-bold cursive justify-center text-center text-base lg:text-4xl'>Software Engineer.</h2>
                <h2 className='text-gray-200 font-bold cursive justify-center text-center text-base lg:text-4xl'>Full stack web developer.</h2>
              </div>
            }
          </div>
      </div>
      
      {!isMobile && <div className='flex justify-around mx-64 py-5 my-40 bg-gray-600 rounded-lg w-2/3 2xl:w-1/2'>
          <div className='flex flex-col items-center justify-center w-1/2'>
            <h3 className='flex cursive text-2xl font-bold text-blue-200 w-2/3 pb-10'>Welcome to my web page!</h3>
            <p className='flex text-xl leading-10 items-center font-medium justify-center text-white w-2/3'>{homeText}</p>
          </div>
          
          
          <img 
            src={portrait}
            alt='Jake Petersen'
            className='rounded-full my-15 h-1/3 w-1/3'
          />
      </div>}

      {isMobile && <div className='flex flex-col justify-center items-center mx-64 py-5 my-20 bg-gray-600 rounded-lg w-4/5'>
          <div className='flex flex-row items-center justify-center align-middle w-full px-5 pb-2'>
            <h3 className='flex cursive text-lg font-bold text-blue-200 w-2/3 text-center'>Welcome to my web page!</h3>
            <img 
              src={portrait}
              alt='Jake Petersen'
              className='rounded-full h-1/3 w-1/3'
            />
          </div>
          
          <p className='pt-2 flex text-sm text-center leading-7 items-center font-medium justify-center text-white w-4/5'>{homeText}</p>
          
      </div>}

      <div className='container mx-auto mb-24'>
        <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-8'>
          {cards && cards.map((section) => (
            <RoutingCard section={section} key={section.title}/>
          ))}
        </div>
      </div>

    </div>
  );
}
