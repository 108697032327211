import React from 'react'

export default function Warning({onAccepted}) {
    const warning = `Thanks for visiting my site! This website is under active development, and does not yet have full mobile support. Apologies if you run into any styling issues, the job hunt can't wait!`

    return (
        <div className='z-30 flex flex-col items-center'>
            <div className='flex flex-col justify-center items-center mx-64 py-5 my-40 bg-slate-400 rounded-lg w-4/5 lg:w-1/2'>
                <h3 className='text-red-700 text-xl lg:text-3xl font-bold mb-2'>
                    Heads Up!
                    {/* <a href={project.link} alt={project.title} target='_blank' rel="noopener noreferrer">
                    {project.title}
                    </a> */}
                </h3>
                <p className='my-6 px-5 lg:px-20 text-base lg:text-lg text-gray-700 font-medium leading-relaxed text-center'>{warning}</p>
                <button className='rounded-full px-24 py-2 text-2xl text-bold bg-green-400 hover:bg-green-600 hover:underline' onClick={onAccepted}>Got It!</button>
            </div>
        </div>
  )
}
