import React from 'react';
import { NavLink } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons'

export default function NavBar({isMobile}) {
  return (
    <div className='z-40' style={{position: 'sticky', top: 0}}>
        {!isMobile && <header className='header py-3'>
            <div className='container mx-auto flex justify-center items-center'>
                <div className='inline-flex py-3 px-3 my-6 w-1/6'>
                    <SocialIcon url='https://www.linkedin.com/in/jpetersen6202/' className='mr-4' target='_blank' fgColor='#fff' style={{height: 35, width: 35}} />
                    <SocialIcon url='https://github.com/jpetersen6202' className='mr-4' target='_blank' fgColor='#fff' style={{height: 35, width: 35}} />
                </div>
                <div className='nav-bar bg-gray-800 bg-opacity-40 rounded-full'>
                    <nav className='flex'>
                        <NavLink 
                            to='/'  
                            className={({isActive}) => 'inline-flex items-center py-6 px-3 mr-4 text-white hover:text-green-200 text-4xl font-bold cursive tracking-widest' + (isActive ? ' text-white' : '')}
                        >
                            &#60;Jake Petersen /&#62;
                        </NavLink>
                        <NavLink 
                            to='/experience'
                            className={({isActive}) => 'inline-flex items-center py-3 px-3 my-6 rounded text-white hover:text-green-200' + (isActive ? ' text-gray-800 bg-gray-200 bg-opacity-70 hover:text-green-800 rounded-full' : '')}
                        >
                            Work Experience
                        </NavLink>
                        <NavLink 
                            to='/projects'
                            className={({isActive}) => 'inline-flex items-center py-3 px-3 my-6 rounded text-white hover:text-green-200' + (isActive ? ' text-gray-800 bg-gray-200 bg-opacity-70 hover:text-green-800 rounded-full' : '')}
                        >
                            Projects
                        </NavLink>
                        <NavLink 
                            to='/skills'
                            className={({isActive}) => 'inline-flex items-center py-3 px-3 my-6 mr-3 rounded text-white hover:text-green-400' + (isActive ? ' text-gray-800 bg-gray-200 bg-opacity-70 hover:text-green-800 rounded-full' : '')}
                        >
                            Skills
                        </NavLink>
                    </nav>
                </div>
                <div className='w-1/6'></div>
            </div>
        </header>} 



        {isMobile && <header className='header-mobile py-3 bg-cover'>
            <div className='container mx-auto flex justify-center'>
                <div className='nav-bar bg-gray-800 bg-opacity-40 rounded-full'>
                    <nav className='flex'>
                        <NavLink 
                            to='/'  
                            className={({isActive}) => 'inline-flex items-center py-2 px-1 mr-2 text-white hover:text-green-200 text-base font-bold cursive tracking-widest' + (isActive ? ' text-white' : '')}
                        >
                            &#60;Jake P/&#62;
                        </NavLink>
                        <NavLink 
                            to='/experience'
                            className={({isActive}) => 'inline-flex text-xs items-center py-2 px-1 my-3 rounded text-white hover:text-green-200' + (isActive ? ' text-gray-800 bg-gray-200 bg-opacity-70 hover:text-green-800 rounded-full' : '')}
                        >
                            Experience
                        </NavLink>
                        <NavLink 
                            to='/projects'
                            className={({isActive}) => 'inline-flex text-xs items-center py-2 px-1 my-3 rounded text-white hover:text-green-200' + (isActive ? ' text-gray-800 bg-gray-200 bg-opacity-70 hover:text-green-800 rounded-full' : '')}
                        >
                            Projects
                        </NavLink>
                        <NavLink 
                            to='/skills'
                            className={({isActive}) => 'inline-flex text-xs items-center py-2 px-1 my-3 mr-3 rounded text-white hover:text-green-400' + (isActive ? ' text-gray-800 bg-gray-200 bg-opacity-70 hover:text-green-800 rounded-full' : '')}
                        >
                            Skills
                        </NavLink>
                    </nav>
                </div>
            </div>
        </header>} 

    </div>
  )
}
