import resume from '../assets/resume.png'
import file from '../assets/jpetersenResume.pdf'

export default function Resume() {
  return (
    <div className='pt-24 flex flex-col items-center'>
        <img src={resume} alt={'Jakes Resume'} className='object-contain pb-8'/>

        <a href={file} download="jake_petersen_resume" className='pb-6'>
            <h3 className='cursive text-center text-gray-800 text-3xl py-5 px-20 bg-sky-800/90 text-gray-200 hover:bg-sky-900/90 hover:text-bold hover:underline hover:text-green-600 rounded'>Download</h3>
        </a>
    </div>
  )
}
