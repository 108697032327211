import portrait from '../assets/p3.jpg'

export default function ContactMe({isMobile}) {
  
  return (
    <main className="relative">

      <div className='p-6 lg:p-10 container mx-auto relative'>
      
        <div className='bg-slate-700 rounded-lg shadow-2xl lg:flex lg:flex-col items-start px-4 lg:p-20'>
          
          <div className='text-lg flex flex-col lg:flex-row justify-center items-center'>
            <img src={portrait} alt={'Jake Petersen'} className='rounded mt-6 lg:mt-0 mb-6 w-64 h-64 lg:mr-12'/>
            <div className='flex flex-col'>
              <h1 className='cursive text-3xl lg:text-6xl text-white mb-8 text-center'>
                Lets get in touch!
              </h1>
              <h1 className='text-white text-medium text-base lg:text-2xl cursive mb-2 lg:mb-6'>
                - Email me at {' '}
                <span className='text-green-100'>{'jpetersen6202@gmail.com'}</span>
              </h1>
              <h1 className='text-white text-medium text-base lg:text-2xl cursive mb-6 lg:mb-2'>
                - Click to visit my{' '}
                <a href={'https://www.linkedin.com/in/jpetersen6202/'} alt={'Linked In'} target='_blank' rel="noopener noreferrer">
                    <span className='text-green-100 hover:underline hover:text-blue-300 hover:text-bold'>LinkedIn</span>
                </a>
              </h1>


            </div>
          </div>          
        </div>
      </div>

      
    </main>
  );
}
