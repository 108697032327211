import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {client} from '../client'
import BlockContent from '@sanity/block-content-to-react'

export default function SingleProject() {
  const [singleProject, setSingleProject] = useState(null)
  const { slug } = useParams();
  useEffect(() => {
    client.fetch(`*[slug.current == "${slug}"]{
      title,
      _id,
      slug,
      company,
      description,
      body,
      projectType,
      images[]->{..., image{asset->{_id,url}}},
      link,
      tags
    }`).then((data) => setSingleProject(data[0])).catch(console.error)
  }, [slug])
  
  if (!singleProject) return <div>Loading...</div>

  return (
    <main className='min-h-screen p-6 lg:p-12'>
        <article className='container shadow-lg mx-auto bg-gray-600 rounded-lg mb-24'>
            <div className='px-8 lg:px-48 py-12 lg:py-20 max-w-full'>
                <h3 className='flex cursive text-2xl lg:text-4xl font-bold text-blue-100 lg:pb-2'>{singleProject.title}</h3>
                <div className='mt-4 pb-4 lg:pb-8 border-b-2'>
                    {singleProject.tags.length > 0 && singleProject.tags.map((tag) => (
                        <p key={`${singleProject.title}-${tag}`} className='bg-emerald-400/75 lg:inline lg:px-4 lg:mr-2 py-2 rounded-full mb-2 lg:mb-6 text-center font-bold text-xs lg:text-sm text-gray-800'>{tag}</p>
                        ))}
                </div>
                <div className='prose lg:prose-xl text-white my-12'>
                  <BlockContent blocks={singleProject.body} projectId='g5c9f8kd' dataset='production'/>
                </div>
                
                {singleProject.images && <h3 className='flex cursive text-2xl font-bold text-blue-100 pb-2'>Gallery</h3> }
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
                    {singleProject.images && singleProject.images.map((image, index) => (
                        <div key={`projectImage-${index}`} className='flex flex-col justify-center text-center'>
                            <img className='object-contain items-center' src={image.image.asset.url} alt={image.caption} />
                            <p className='mt-2 text-gray-200'>{image.caption}</p>
                        </div>
                    ))}
                </div>
            </div>
        </article>
    </main>
  );
}
