import React, {useState, useEffect} from 'react';
import { client } from '../client'
import ExperienceCard from './ExperienceCard';

export default function Post() {
  const [postData, setPostData] = useState(null);
  
  useEffect(() => {
    client.fetch(`*[_type == "post"]{
      title,
      slug,
      mainImage{
        asset->{
          _id,
          url
        },
        alt
      }
    }`).then((data) => setPostData(data))
    .catch(console.error)
  }, [])
  
  return (
    <main className='min-h-screen p-12'>
      <section className='container mx-auto'>
        <h1 className='text-3xl lg:text-5xl flex justify-center text-white cursive pb-4'>Work Experience</h1>
        <h2 className='text-base lg:text-lg text-gray-400 flex justify-center text-center'>Here are the teams I've contributed to throughout my professional career.</h2>
        <h2 className='text-base lg:text-lg text-gray-300 flex justify-center text-center mb-12 font-bold'>Click on a card to see more details!</h2>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-12'>
          {postData && postData.map((post) => (
            <ExperienceCard post={post} key={post.title}/>
          ))}
        </div>
      </section>
    </main>
  );
}
