import React from 'react'
export default function SkillsCard( {skill} ) {
  
    return (
    <article className='w-3/4'>
        <span className='rounded-lg block h-28 lg:h-40 2xl:h-48 relative shadow-2xl leading-snug bg-white border-slate-500'>
            <img 
            src={skill.image.asset.url}
            alt={skill.title}
            className='w-full h-full rounded object-cover'
            />
        </span>
        <h3 className='cursive text-center text-base lg:text-lg pb-3 lg:py-3 text-gray-200'>{skill.title}</h3>
    </article>
  )
}
